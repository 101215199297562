import React from 'react';

import Layout from '../components/layout';
import Logo from '../components/logo';
import Menu from '../components/menu';
import Section, { InnerSection } from '../components/section';
import Slider from '../components/slider';
import Footer from '../components/footer';
import { ModalContext } from '../components/context/Modal';
import ListItem from '../components/list-item';

export default function Success(props) {
  return (
    <Layout>
      <div className="header sub-page communities-header">
        <Menu {...props} />
      </div>
      <div className="body-container sub-page communities">
        <h1>Thanks for the message!</h1>
      </div>
      <Footer />
    </Layout>
  );
}
