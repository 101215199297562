import React from 'react';

export default function ListItem({
  name,
  location,
  features,
  image,
  url,
  onMouseEnter,
  onMouseLeave,
  hovering,
}) {
  function handleOpenLink() {
    window.open(url, '_blank');
  }

  return (
    <div
      className="list-item"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <img className="list-item-bg" src={image} />
      <div className="list-item-content">
        <div className={`title-section${hovering ? ' hovering' : ''}`}>
          <div className="title">
            <span className="bold">{name}</span>
            <span>{location}</span>
          </div>
          <div className="details">
            <p>{features[0]}</p>
            <p>{features[1]}</p>
            <p>{features[2]}</p>
          </div>
          <button onClick={handleOpenLink} className="visit-site-btn">
            Visit Site
          </button>
        </div>
      </div>
    </div>
  );
}
